
<template>
<div class="p-2 mb-2">
  <div v-if="this.publicbanners.length > 0">
    <template v-for="publicbanner in publicbanners">
      <vs-alert :key="publicbanner.id+'_pBanner'"
            :title=publicbanner.title
            :active=publicbanner.active
            :color=publicbanner.color
            class="mb-2">{{publicbanner.text}}</vs-alert>
    </template>
  </div>
  <div v-if="this.privatebanners.length > 0">
    <template v-for="privatebanner in privatebanners">
      <vs-alert :key="privatebanner.id+'_pBanner'"
            :title=privatebanner.title
            :active=privatebanner.active
            :color=privatebanner.color
            class="mb-2">{{privatebanner.text}}</vs-alert>
    </template>
  </div>
</div>
</template>

<script>
import codeHelper from '../helper/staticCodeHelper';

export default {
  name: "AlertQueue",
  components: {
  },
  data: function (){
      return {
        publicbanners:[],
        privatebanners:[]
      };
  },
  methods: {
    ...codeHelper,
    showError: function(error){
      if(error != null)
      {
        this.$vs.notify({
          title:error.titel,
          text:error.text,
          icon:error.icon,
          color:"danger",
          time:5000
          });
          
        this.$store.dispatch('alertqueue/removeError', { root: true },{ root: true }); 
      }
    },
    showWarning: function(warning){
      if(warning != null)
      {
        this.$vs.notify({
          title:warning.titel,
          text:warning.text,
          icon:warning.icon,
          color:"warning",
          time:5000
          });
          
        this.$store.dispatch('alertqueue/removeWarning', { root: true },{ root: true }); 
      }
    },
    showSuccess: function(success){
      if(success != null)
      {
        this.$vs.notify({
          title:success.titel,
          text:success.text,
          icon:success.icon,
          color:"success",
          time:5000
          });
          
        this.$store.dispatch('alertqueue/removeSuccess', { root: true },{ root: true }); 
      }
    },
    showInfo: function(info){
      if(info != null)
      {
        this.$vs.notify({
          title:info.titel,
          text:info.text,
          color:"primary",
          icon:info.icon,
          time:5000
          });
          
        this.$store.dispatch('alertqueue/removeInfo', { root: true },{ root: true }); 
      }
    },
    showLoader: function(showloader){
      var success = true;
      if(showloader != null)
      {
        var id = "";
        if(typeof(showloader) != 'undefined')
        {
            id = showloader.id;
        }
        if(id !== "" && id !== "none")
        {
          try {
            this.$vs.loading({
            container: id,
            scale: showloader.scale
            });
          } 
          catch {
            success = false;
          }
          
        }
        else
        {
          this.$vs.loading();
        }

        this.$store.dispatch('alertqueue/removeLoaderFromShow', { root: true },{ root: true }); 
      }
      return success;
    },
    hideLoader: function(hideloader){
      if(hideloader != null)
      {
        var id = "";
        if(typeof(hideloader) != 'undefined')
        {
            id = hideloader.id;
        }
        if(id !== "" && id !== "none")
        {
          this.$vs.loading.close(id +" > .con-vs-loading");
        }
        else
        {
          this.$vs.loading.close();
        }

        this.$store.dispatch('alertqueue/removeLoaderFromHide', { root: true },{ root: true }); 
      }
    }
  },  
  computed:{
    queuedErrors(){
      return this.$store.state.alertqueue.errors;
    },
    queuedWarnings(){
      return this.$store.state.alertqueue.warnings;
    },
    queuedSuccess(){
      return this.$store.state.alertqueue.success;
    },
    queuedInfos(){
      return this.$store.state.alertqueue.infos;
    },    
    queuedShowLoader(){
      if(this.IsNotNull(this.$store.state.alertqueue.showLoader))
      {
        return this.$store.state.alertqueue.showLoader;
      }
      else
      {
        return [];
      }
    },
    queuedHideLoader(){
      return this.$store.state.alertqueue.hideLoader;
    },
    activePublicBanner(){
      return this.$store.state.polling.publicbanners.data;
    },
    activePrivateBanner(){
      return this.$store.state.polling.privatebanners.data;
    }
  },
  watch:{
    activePublicBanner(banners){
      this.publicbanners = banners;
    },
    activePrivateBanner(banners){
      this.privatebanners = banners;
    },    
    queuedErrors(errors){

      if(typeof(errors) != 'undefined')
      {
        for(var i = 0; i < errors.length;i++)
        {
          this.showError(errors[i]);
        }
      }

    },
    queuedWarnings(warnings){
      if(typeof(warnings) != 'undefined')
      {
        for(var i = 0; i < warnings.length;i++)
        {
          this.showWarning(warnings[i]);
        }
      }
    },
    queuedSuccess(success){
      if(typeof(success) != 'undefined')
      {
        for(var i = 0; i < success.length;i++)
        {
          this.showSuccess(success[i]);
        }
      }
    },
    queuedInfos(info){
      if(typeof(info) != 'undefined')
      {
        for(var i = 0; i < info.length;i++)
        {
          this.showInfo(info[i]);
        }
      }
    },    
    queuedShowLoader(showLoader){

      if(this.IsNotNull(showLoader))
      {
        for(var i = 0; i < showLoader.length;i++)
        {
          if(this.showLoader(showLoader[i]) == false)
          {
            this.$store.state.alertqueue.showLoader = [];
            break;
          }
        }
      }
    },
    queuedHideLoader(hideLoader){
      if(typeof(hideLoader) != 'undefined')
      {
        for(var i = 0; i < hideLoader.length;i++)
        {
          this.hideLoader(hideLoader[i]);
        }
      }
    }
  }
};
</script>