<template>
<div id="bg">
  <AlertQueue></AlertQueue>
  <div id="login_box" class="centered">
    <img :src="require('@/assets/images/logo/ie_logo_menu.png')"/>
    <h1 class="">Passwort ändern</h1>
    <p class="py-5 text-center">
      Bitte vergeben Sie ein neues Passwort.
    </p>
    <div >
      <form @submit.prevent="handleSubmit">

        <div class="form-group">
          <vs-input
          id="old_password"
          type="password"
          icon-border
          icon="lock"
          class="mt-3 width100proz"
          label-placeholder="Altes Passwort"
          v-model="password_old"/>
        </div>
        <vs-divider/>
        <div class="form-group">
          <vs-input
          type="password"
          icon-border
          icon="lock"
          class="mt-3 width100proz"
          label-placeholder="Neues Passwort"
          :success="isPasswordNewOk"
          :danger="isPasswordNewFail"
          val-icon-success="done"
          val-icon-danger="clear"
          :danger-text="password_error_text"
          v-model="password_new"/>
          <vs-progress v-if="password_new" :percent="password_level" :color="password_color"></vs-progress>
        </div>

        <div class="form-group">
          <vs-input
          type="password"
          icon-border
          icon="lock"
          class="mt-3 width100proz"
          label-placeholder="Passwort wiederholen"
          :success="isPasswordNew2Ok"
          :danger="isPasswordNew2Fail"
          danger-text="Passwörter stimmen nicht überein"
          val-icon-success="done"
          val-icon-danger = "clear"          
          v-model="password_new2"/>
        </div>
        
        <div class="form-group">
          <button color="primary" class="width100proz btn btn-primary" :disabled="disableButton" type="submit" >Passwort ändern</button>
          
        </div>
        <div class="form-group">
          <vs-alert v-show="submitted && !password_old" color="danger" icon="error_outline">
            <span>
              Bitte altes Passwort eingeben!
            </span>
          </vs-alert>
        </div>
        <div class="form-group">
          <vs-alert v-show="submitted && !password_new" color="danger" icon="error_outline">
            <span>
              Bitte neues Passwort eingeben!
            </span>
          </vs-alert>          
        </div>
        <div class="form-group">
          <vs-alert v-show="submitted && !password_new2" color="danger" icon="error_outline">
            <span>
              Bitte neues Passwort wiederholen!
            </span>
          </vs-alert>          
        </div>          
      </form>
      
      <div class="form-group">
        <button v-if="AllowCancel" @click="GoToDashboard" color="primary" class="mt-2 width100proz btn btn-primary" >Abbrechen</button>
      </div>
      
    </div>
  </div>
</div>
</template>

<script>

import {router} from '../../router';
import AlertQueue from '../AlertQueue';

export default {
  name: "ResetPasswordPage",
  components: {
    AlertQueue
  },
  props:[
    'AllowCancel'
  ],
  data: () => ({
    password_old: '',
    password_new:'',
    password_new2:'',
    submitted: false,
    password_level:0,
    password_color:'danger',
    password_error_text:""
  }),
    computed: {
        changingPassword () {
            return this.$store.state.authentication.changingPassword;
        },
        changeFail () 
        {
            return this.$store.state.authentication.changeFail;
        },
        getErrorMessage () {
          return this.$store.state.alert.message;
        },
        isPasswordNew2Fail(){
            
            var ret = false;
            if(this.password_new != "" && this.password_new2 != "" && this.password_new != this.password_new2)
                ret = true;

            return ret;
        },
        isPasswordNew2Ok(){
            var ret = false;

            if(this.password_new != "" && this.password_new2 != "" && this.password_new == this.password_new2)
                ret = true;

            return ret;
        },
        isPasswordNewOk(){
            var ret = false;

            if(this.password_new != "")
            {
                ret = this.checkPassword(this.password_new);
            }
            return ret;
        },
        isPasswordNewFail(){
            var ret = false;

            if(this.password_new != "")
            {
                ret = !this.checkPassword(this.password_new);
            }
            return ret;
        },
        disableButton(){
            return !(this.isPasswordNew2Ok && this.isPasswordNewOk && this.password_old != "");
        }
    },
    created () {
        // reset login status
        localStorage.removeItem('authtokens');
        this.$vs.loading.close();
    },
    watch: {
      changeFail(value){
          if(value)
          {
            this.submitted = false;
            this.password_old = "";
            this.password_new = "";
            this.password_new2 = "";
          }
      },
      changingPassword (value)
      {
        if(value)
          this.$vs.loading();
        else
          this.$vs.loading.close();

      } 

    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        handleSubmit (e) {
            this.submitted = true;
            const { password_old,password_new,password_new2 } = this;
            const { dispatch } = this.$store;

            if (password_old && password_new && password_new == password_new2)
            {
                dispatch('authentication/changePassword', { password_old, password_new });
                document.getElementById('old_password').focus();     
            }
        },
        GoToDashboard: function (){
          //router.push({ name: 'Dashboard' });
          //gehe dahin wo du vorher warst
          router.go(-1);
        },
        checkPassword: function (password)
        {
            var ret = false;
            var strength=0;
            var text_start = "Bitte ";
            var text = "";
            if (password.match(/[a-z]+/)){
                strength+=1;
            }
            else
            {
                text+=" a-z "
            }
            if (password.match(/[A-Z]+/)){
                strength+=1;
            }
            else
            {
                text+=" A-Z "
            }
            if (password.match(/[0-9]+/)){
                strength+=1;
            }
            else
            {
                text+=" 0-9 "
            }            
            if (password.match(/[$#&!]+/)){
                strength+=1;
            }  
            else
            {
                text+=" ($#&!) "
            }

            if(password.length < 6 )
            {
                this.password_level=15;
                this.password_color = "danger";
                text+=" min. 6 Zeichen ";
            }
            else
            {
                switch(strength)
                {
                case 0:
                    this.password_level=10;
                    this.password_color = "danger";
                    break;

                case 1:
                    this.password_level=25;
                    this.password_color = "danger";
                    break;

                case 2:
                    this.password_level=50;
                    this.password_color = "danger";
                    break;

                case 3:
                    this.password_level=75;
                    this.password_color = "warning";
                    break;

                case 4:
                    this.password_level=100;
                    this.password_color = "success";
                    ret=true;
                    break; 
                }    
            }
            
            if(text != "")
            {
               this.password_error_text = text_start + text + " verwenden!";
            }
            else
                this.password_error_text = "";

            return ret;         
        }
    }    
};
</script>

<style>
  body, html {
    height: 100%;
  }
  #app{
    height: 100%;
  }
  #bg {
    /* The image used */
    background-image: url("../../assets/images/background/login.jpg");
    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

#login_box {
  background-color:white;
  padding:30px 20px 30px 20px;
  box-shadow: 8px 8px 30px 3px #c3c3c3;
	border: 1px solid #c3c3c3
}
#login_box i{
  font-size: 18px;
}  
#login_box h1
{
    text-align: center;
}
#login_box img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>